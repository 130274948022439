import "./TrendForecast.scss";

export default function TrendForecast() {

  return (
    <div className="trend_forecast_container">
        <h1>Coming Soon..</h1>
    </div>
  );
}
