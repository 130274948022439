import "./Promotion.scss";

export default function Promotion() {

  return (
    <div className="promotion_container">
        <h1>Coming Soon..</h1>
    </div>
  );
}
