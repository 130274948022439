import "./ReadyBrandLaunch.scss";

export default function ReadyBrandLaunch() {

  return (
    <div className="ready_brand_container">
        <h1>Coming Soon..</h1>
    </div>
  );
}
